export type State = { [key: string]: any }
export type ConnectArgs = State
type Curry = (args: State) => void
type GlobalState = { [key: string]: State }
type SetState = (values: State, callback?: (actualState: State) => void) => void
type SubscribeCallback = (state: State) => void
type ConnectCallback = (props: Connect) => Curry | void
export type Connect = {
	getState: () => State
	globalState: State
	setState: SetState
	connect: (callback: ConnectCallback) => Curry
}
type UseState = {
	connect: (callback: ConnectCallback) => Curry
	setState: SetState
	getState: () => State
	subscribe: (callback: SubscribeCallback) => void
}

let globalState = {} as GlobalState

export const useState = (name: string, initialState?: State): UseState => {
	const events = [] as SubscribeCallback[]
	let state = initialState || {}

	const subscribe = (callback: (state: State) => void) => {
		events.push(callback)
	}

	const dispatchSubscribes = () => {
		const eventsLength = events.length
		for (let i = 0; i < eventsLength; i++) {
			events[i](getState())
		}
	}

	const getState = () => state

	const setState = (values: State, callback?: (state: State) => void) => {
		state = {
			...state,
			...values,
		}
		globalState = {
			...globalState,
			[name]: { ...globalState[name], ...state },
		}
		dispatchSubscribes()
		if (callback) {
			callback(getState())
		}
	}

	const connect = (callback: ConnectCallback) => {
		if (callback) {
			const dispatch = callback({ getState, setState, globalState, connect })

			if (typeof dispatch === 'function') {
				return (args: ConnectArgs) => {
					dispatch(args)
				}
			}
		}
		return () => {}
	}

	return {
		connect,
		setState,
		getState,
		subscribe,
	}
}
