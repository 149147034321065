var blockResizerStoredMemory = {
	selectedDevice: '',
}
export const blocksAdjustment = (adjust, sortUrl, resizeUrl) => {
	var resizeAllowed = true
	updateVariableResizeAllowed()
	if (resizeAllowed === false) {
		if ($(adjust).hasClass('clicked') === false) {
			toastForSmallWindowForResize()
		}
	}
	var blocks = $('.mainContainer .blocks-box > .columns'),
		dropdownResizeDevices = $('.dropdown-resize-devices')

	if (resizeAllowed) {
		$('.account .dropdown-resize-options').toggleClass('resize-options-open')
		$(dropdownResizeDevices).removeClass('resize-devices-selected')
		$('.dropdown-resize-options .dropdown-resize-devices:first-child').toggleClass('resize-devices-selected')
	}

	$(adjust).toggleClass('clicked')

	$(dropdownResizeDevices).click(function() {
		updateVariableResizeAllowed()

		if (resizeAllowed) {
			$(dropdownResizeDevices).removeClass('resize-devices-selected')
			$(this).addClass('resize-devices-selected')
			setChosenDeviceToVariable()

			setTimeout(show.charts, 500)
		} else {
			toastForSmallWindowForResize()
		}

		pageViewForResponsiveResize()
	})

	if ($(adjust).hasClass('clicked')) {
		$('div.columns.anmt').addClass('anmt--denied')
		$('.blocks-box > .columns.wrapper-content .wrapper').append('<div class="sortMe">sort me!</div>')

		if (resizeAllowed) {
			sendResizeAjax(setBlocksHeight(blocks), resizeUrl)
			setTimeout(show.charts, 500)
			resize.on(blocks, resizeUrl)
			$('body').addClass('no-overflow')
			var blocksContainerHeight =
				$(window).height() -
				$('header').outerHeight() -
				$('section.page-info').outerHeight() -
				parseInt($('.mainContainer').css('padding-top'))
			$($(blocks).parent())
				.height(blocksContainerHeight)
				.css({ overflow: 'auto', 'overflow-x': 'hidden' })

			sort.on($(blocks).parent(), sortUrl, false, resizeUrl)
		}
		grider.start($(blocks).parent(), false)

		wrappersAdjustment()
	} else {
		var preventScroll = $('.blocks-box').scrollTop()
		$('.blocks-box').scrollTop(0)

		$('div.columns.anmt').removeClass('anmt--denied')
		$('div.sortMe.clickedSortMe').trigger('click')
		$('.sortMe').remove()

		if (resizeAllowed) {
			setTimeout(show.charts, 500)
			resize.off(blocks)
			$('body').removeClass('no-overflow')
			$(blocks)
				.parent()
				.removeAttr('style')
			sort.off($(blocks).parent())
		}
		grider.destroy()

		$('html, body').scrollTop(preventScroll > 0 ? preventScroll + $('.blocks-box').offset().top : preventScroll)

		cleanPageViewForResponsiveResize()
		$('.dropdown-resize-devices').removeClass('resize-devices-selected')
		setBlocksHeightRecalculation()
	}

	function updateVariableResizeAllowed() {
		if ($(window).width() <= 998) {
			resizeAllowed = false
		} else {
			resizeAllowed = true
		}
	}

	function wrappersAdjustment() {
		$('div.sortMe').click(function() {
			var blocksInWrapper = $(this)
				.parent()
				.find('> .columns')

			$(this).toggleClass('clickedSortMe')

			if ($(this).hasClass('clickedSortMe')) {
				$('div.sortMe').hide()
				$(this)
					.show()
					.text('cancel sort!')

				sendResizeAjax(setBlocksHeight(blocksInWrapper), resizeUrl)
				resize.off(blocks)
				sort.off($(blocks).parent())

				grider.destroy()

				resize.on(blocksInWrapper, resizeUrl)
				sort.on($(blocksInWrapper).parent(), sortUrl, true, resizeUrl)

				grider.start($(blocksInWrapper).parent(), true)
			} else {
				$('div.sortMe').show()
				$(this).text('sort me!')

				resize.off(blocksInWrapper)
				sort.off($(blocksInWrapper).parent())

				grider.destroy()

				resize.on(blocks, resizeUrl)
				sort.on($(blocks).parent(), sortUrl, false, resizeUrl)

				grider.start($(blocks).parent(), false)
			}
		})
	}
}
export const resize = {
	on: function(element, url) {
		this.init(element, url)
		$(element).resizable('enable')
	},
	off: function(element) {
		$(element).resizable('disable')
	},
	init: function(element, url) {
		var grid = {
				width: Math.round(
					$(element)
						.parent()
						.innerWidth() / 12,
				),
				height: 80,
			},
			block = {
				width: 0,
				height: 0,
				originalWidth: 0,
				originalHeight: 0,
				deviceType: 0,
				resizeDirection: 0,
			},
			basicBlockSizes = {
				originalBlockAttrWidth: 0,
				originalBlockAttrHeight: 0,
			},
			tolerance = 8, //allowed tolerance (border-width of .columns)
			changedWidth = false,
			changedHeight = false,
			getSideDiff = function(_thisResizedBlock, diff, targetObject, resizeSide) {
				var sideNameForOriginal = 'original' + resizeSide[0].toUpperCase() + resizeSide.slice(1, resizeSide.length)
				if (Math.abs(diff) >= grid[resizeSide] - tolerance && Math.abs(diff) < grid[resizeSide] + tolerance) {
					if (diff < 0) {
						block[resizeSide] += 1
					} else {
						block[resizeSide] -= 1
					}

					block[sideNameForOriginal] = Math.round(targetObject['size'][resizeSide])
					changedHeight = true

					$(_thisResizedBlock)
						.removeAttr('style')
						.attr(resizeSide, block[resizeSide])
				}
			}

		$(element).resizable({
			grid: [grid.width, grid.height],
			handles: 's, e',
			minHeight: 0,
			start: function(event, ui) {
				var direction = ui.element.data('ui-resizable').axis //return "s" or "e"
				$(this)
					.find('.ui-resizable-' + direction)
					.addClass('ui-resizable-active-' + direction)

				basicBlockSizes.originalBlockAttrWidth = $(this).attr('width')
				basicBlockSizes.originalBlockAttrHeight = $(this).attr('height')

				if (
					blockResizerStoredMemory.selectedDevice === 'tablet' ||
					blockResizerStoredMemory.selectedDevice === 'mobile'
				) {
					block.width =
						parseInt(this.getAttribute('width-resizer')) > 0 ? parseInt(this.getAttribute('width-resizer')) : 1
					block.height =
						parseInt(this.getAttribute('height-resizer')) > 0 ? parseInt(this.getAttribute('height-resizer')) : 1
				} else {
					block.width = parseInt(this.getAttribute('width')) > 0 ? parseInt(this.getAttribute('width')) : 1
					block.height = parseInt(this.getAttribute('height')) >= 0 ? parseInt(this.getAttribute('height')) : 0
				}

				block.originalWidth = Math.round(ui.originalSize.width)
				block.originalHeight = Math.round(ui.originalSize.height)

				if (block.height === 0) {
					block.height = Math.ceil(block.originalHeight / grid.height)

					if (
						blockResizerStoredMemory.selectedDevice === 'tablet' ||
						blockResizerStoredMemory.selectedDevice === 'mobile'
					) {
						$(this)
							.removeAttr('style')
							.attr('height-resizer', block.height)
					} else {
						$(this)
							.removeAttr('style')
							.attr('height', block.height)
					}
				}
				//resize columns on the same row
				var row = $(this).attr('data-row'),
					changedBlocks = '> .columns[data-row="' + row + '"]'

				$(element)
					.parent()
					.find(changedBlocks)
					.each(function() {
						$(this).attr('data-diffheight', block.height)

						if (
							blockResizerStoredMemory.selectedDevice === 'tablet' ||
							blockResizerStoredMemory.selectedDevice === 'mobile'
						) {
							$(this)
								.attr('height-' + blockResizerStoredMemory.selectedDevice, block.height)
								.attr('height-resizer', block.height)
						} else {
							$(this).attr('height', block.height)
						}
					})
			},
			resize: function(event, ui) {
				var direction = ui.element.data('ui-resizable').axis,
					diff = 0

				if (direction === 's') {
					diff = block.originalHeight - Math.round(ui.size.height)

					getSideDiff(this, diff, ui, 'height')
					setSizeAttributesAtResize('height', 'originalBlockAttrHeight', this)
				} else if (direction === 'e') {
					diff = block.originalWidth - Math.round(ui.size.width)

					getSideDiff(this, diff, ui, 'width')
					setSizeAttributesAtResize('width', 'originalBlockAttrWidth', this)
				}

				function setSizeAttributesAtResize(resizeSide, resizeSideOriginal, _thisResizedBlock) {
					var parameterNameSide = resizeSide,
						parameterNameResizer = resizeSide + '-resizer'

					if (
						blockResizerStoredMemory.selectedDevice === 'tablet' ||
						blockResizerStoredMemory.selectedDevice === 'mobile'
					) {
						parameterNameSide = resizeSide + '-' + blockResizerStoredMemory.selectedDevice
						$(_thisResizedBlock).attr(parameterNameSide, block[resizeSide])
						$(_thisResizedBlock).attr(parameterNameResizer, block[resizeSide])
						$(_thisResizedBlock).attr(resizeSide, basicBlockSizes[resizeSideOriginal])
					} else {
						$(_thisResizedBlock).attr(resizeSide, block[resizeSide])
					}
				}
			},
			stop: function(event, ui) {
				var direction = ui.element.data('ui-resizable').axis,
					blocksProperties = []

				$(this)
					.find('.ui-resizable-' + direction)
					.removeClass('ui-resizable-active-' + direction)

				var blocks = $('.mainContainer .blocks-box > .columns')
				setBlocksHeight(blocks)

				if (direction === 'e') {
					controlMinimalMaximalBlockSizes('width')

					setSizeAttributesAtStopResize('width', this)

					blocksProperties = setBlocksHeight(element)
					window.blockAttributeData = {
						block_id: $(this).attr('id'),
						resizeDirection: '',
						deviceType: 'desktop',
					}
					prepareResizeDataArray('width', 'east', this)
					blocksProperties.unshift(blockAttributeData)

					controlAjaxSend()
				}

				if (direction === 's') {
					controlMinimalMaximalBlockSizes('height')

					var row = $(this).attr('data-row'),
						changedBlocks = '> .columns[data-row="' + row + '"]'

					$(element)
						.parent()
						.find(changedBlocks)
						.each(function() {
							$(this).attr('data-diffheight', block.height)
							window.blockAttributeData = {
								block_id: $(this).attr('id'),
								resizeDirection: '',
								deviceType: 'desktop',
							}
							setSizeAttributesAtStopResize('height', this)
							prepareResizeDataArray('height', 'south', this)
							blocksProperties.push(blockAttributeData)
						})

					controlAjaxSend()
				}

				setTimeout(show.charts, 500)

				$(this).removeAttr('style') //cleared ui inline style as width: and height:

				changedWidth = false
				changedHeight = false

				function controlMinimalMaximalBlockSizes(blockSelectedSide) {
					block[blockSelectedSide] = Math.min(Math.max(block[blockSelectedSide], 1), 12)
				}

				function setSizeAttributesAtStopResize(resizeSide, _thisResizedBlock) {
					var parameterNameSide = resizeSide,
						parameterNameResizer = resizeSide + '-resizer'

					if (
						blockResizerStoredMemory.selectedDevice === 'tablet' ||
						blockResizerStoredMemory.selectedDevice === 'mobile'
					) {
						parameterNameSide = resizeSide + '-' + blockResizerStoredMemory.selectedDevice
						$(_thisResizedBlock).attr(parameterNameSide, block[resizeSide])
						$(_thisResizedBlock).attr(parameterNameResizer, block[resizeSide])
					} else {
						$(_thisResizedBlock).attr(resizeSide, block[resizeSide])
					}
				}

				function controlAjaxSend() {
					if (blocksProperties.length > 0) {
						sendResizeAjax(blocksProperties, url)
					}
				}

				function prepareResizeDataArray(targetDeviceSide, selectedResizeDirection, _thisResizedBlock) {
					var targetAttributeToDelete = targetDeviceSide,
						targetDeviceSideValue

					blockAttributeData.resizeDirection = selectedResizeDirection

					if (targetDeviceSide === 'width') {
						targetDeviceSideValue = parseInt($(_thisResizedBlock).attr(targetDeviceSide))
					} else if (targetDeviceSide === 'height') {
						targetDeviceSideValue = block.height
					}
					blockAttributeData[targetDeviceSide] = targetDeviceSideValue

					if (
						blockResizerStoredMemory.selectedDevice === 'tablet' ||
						blockResizerStoredMemory.selectedDevice === 'mobile'
					) {
						blockAttributeData.deviceType = blockResizerStoredMemory.selectedDevice
						delete blockAttributeData[targetAttributeToDelete]
						targetDeviceSide = targetDeviceSide + '-' + blockResizerStoredMemory.selectedDevice
						blockAttributeData[targetDeviceSide] = parseInt($(_thisResizedBlock).attr(targetDeviceSide))
					}
				}
			},
		})
	},
}
export const sort = {
	on: function(element, url, wrapper, resizeUrl) {
		this.init(element, url, wrapper, resizeUrl)
		$(element).sortable('enable')
	},
	off: function(element) {
		$(element).sortable('disable')
	},
	init: function(element, url, wrapper, resizeUrl) {
		if (wrapper) {
			$(element).sortable({
				items: '> .columns',
				scroll: false,
				tolerance: 'pointer',
				update: function(event, ui) {
					var order = {}
					var parentBlock = ui.item[0].parentNode
					var subBlocks = parentBlock.querySelectorAll('.columns')

					subBlocks.forEach(function(subBlock, index) {
						var blockId = subBlock.getAttribute('id')
						order[blockId] = subBlocks.length - index
					})

					//send data with AJAX to database
					$.nette.ajax({
						type: 'POST',
						dataType: 'json',
						url: url,
						data: {
							order: order,
							block_id: parentBlock.closest('.columns').getAttribute('id'),
						},
						complete: function() {
							sendResizeAjax(setBlocksHeight($(element).find('> .columns')), resizeUrl)
						},
					})
				},
				placeholder: 'ui-state-highlight',
				forcePlaceholderSize: true,
			})
		} else {
			$(element)
				.sortable({
					items: '> .columns',
					scroll: true,
					scrollSensitivity: 100,
					scrollSpeed: 10,
					tolerance: 'pointer',
					update: function(event, ui) {
						var order = {}
						var blocks = document.querySelectorAll('.mainContainer .blocks-box > .columns')
						blocks.forEach(function(block, index) {
							var blockId = block.getAttribute('id')
							order[blockId] = blocks.length - index
						})

						$.nette.ajax({
							type: 'POST',
							dataType: 'json',
							url: url,
							data: {
								order: order,
							},
							complete: function() {
								sendResizeAjax(setBlocksHeight($(element).find('> .columns')), resizeUrl)
							},
						})
					},
					placeholder: 'ui-state-highlight',
					forcePlaceholderSize: true,
				})
				.disableSelection()
		}
	},
}
export const grider = {
	destroy: function() {
		$('#grider')
			.parent()
			.removeClass('grider-active')
			.find('#grider')
			.remove()
	},
	start: function(element, wrapper) {
		var object = $('<div id="grider" />'),
			container = $('<div class="grider-container" />'),
			grid = {
				width: 100 / 12,
				height: 80,
			},
			w = 0,
			h = 0,
			elementHeight = function() {
				var heights = 0,
					actual = 0
				$(element)
					.find('> .columns[data-row]')
					.each(function() {
						if (actual != parseInt($(this).attr('data-row'))) {
							actual = parseInt($(this).attr('data-row'))
							heights += $(this).outerHeight()
						}
					})

				return heights
			},
			style,
			header = 0

		for (w; w <= 12; w++) {
			if (w === 0 || w === 12) {
				style = 'left: ' + w * grid.width + '%; border: none;'
			} else {
				style = 'left: ' + w * grid.width + '%;'
			}

			$(container).append('<div class="grider-w" style="' + style + '"></div>')
		}

		if (wrapper) {
			if ($(element).find('.wrapper-header').length > 0) {
				header += $(element)
					.find('.wrapper-header')
					.outerHeight()
				$(object).css('top', header)
			}
		}

		for (h; h <= Math.round(elementHeight() / grid.height); h++) {
			if (h === 0 || h === Math.round(elementHeight() / grid.height)) {
				style = 'top: ' + h * grid.height + 'px; border: none;'
			} else {
				style = 'top: ' + h * grid.height + 'px;'
			}

			$(container).append('<div class="grider-h" style="' + style + '"></div>')
		}

		$(object)
			.height(elementHeight())
			.append(container)

		$(element)
			.addClass('grider-active')
			.prepend(object)
	},
}

export function setChosenDeviceToVariable() {
	if ($('.resize-devices-desktop').hasClass('resize-devices-selected')) {
		blockResizerStoredMemory.selectedDevice = 'desktop'
	} else if ($('.resize-devices-tablet').hasClass('resize-devices-selected')) {
		blockResizerStoredMemory.selectedDevice = 'tablet'
	} else if ($('.resize-devices-mobile').hasClass('resize-devices-selected')) {
		blockResizerStoredMemory.selectedDevice = 'mobile'
	}
}

export function setBlocksHeight(element) {
	var rows = getRows(),
		changedHeight = []

	rows.forEach(setMaxHeight)

	$(element).each(function() {
		var maxRowHeight = parseInt($(this).attr('data-diffheight')),
			thisHeight

		// set new block heights according to selected responsive devices
		var responsiveResizeHeightName = 'height',
			responsiveResizeWidthName = 'width',
			responsiveResizeHeightDevice = 'height'

		if (blockResizerStoredMemory.selectedDevice === 'tablet' || blockResizerStoredMemory.selectedDevice === 'mobile') {
			responsiveResizeHeightName = responsiveResizeHeightName + '-resizer'
			responsiveResizeWidthName = responsiveResizeWidthName + '-resizer'
			responsiveResizeHeightDevice = responsiveResizeHeightDevice + '-' + blockResizerStoredMemory.selectedDevice
		}

		thisHeight = parseInt($(this).attr(responsiveResizeHeightName))

		if (thisHeight != maxRowHeight) {
			const changedHeightData = {
				block_id: $(this).attr('id'),
				width: $(this).attr(responsiveResizeWidthName),
			}
			changedHeightData[responsiveResizeHeightDevice] = maxRowHeight
			changedHeight.push(changedHeightData)

			$(this).attr(responsiveResizeHeightName, maxRowHeight)

			if (blockResizerStoredMemory.selectedDevice === 'tablet') {
				$(this).attr(responsiveResizeHeightDevice, maxRowHeight)
			}
		}
	})

	return changedHeight

	function getRows() {
		var row = 1,
			rows = [row],
			position = $(element)
				.eq(0)
				.position().top

		$(element).each(function() {
			var blockPosition = $(this).position()

			if (blockPosition.top > position) {
				position = blockPosition.top
				row += 1
				rows.push(row)
			}

			$(this).attr('data-row', row)
		})

		return rows
	}

	function setMaxHeight(item) {
		var blocksInRow = '> .columns[data-row="' + item + '"]',
			maxRowHeight = 0

		$(element)
			.parent()
			.find(blocksInRow)
			.each(function() {
				var thisHeight
				//target height according to selected devices
				if (
					blockResizerStoredMemory.selectedDevice === 'tablet' ||
					blockResizerStoredMemory.selectedDevice === 'mobile'
				) {
					thisHeight = parseInt($(this).attr('height-resizer'))
				} else {
					thisHeight = parseInt($(this).attr('height'))
				}
				if (thisHeight > maxRowHeight) {
					maxRowHeight = thisHeight
				}
			})

		$(element)
			.parent()
			.find(blocksInRow)
			.attr('data-diffheight', maxRowHeight)
	}
}

export function sendResizeAjax(array, url) {
	if (array.length > 0) {
		var dimensions = JSON.parse(JSON.stringify(array))

		//send data with AJAX to database
		$.nette.ajax({
			type: 'POST',
			dataType: 'json',
			url: url,
			data: {
				properties: dimensions,
			},
		})
	}
}
export function pageViewForResponsiveResize() {
	var elementsForResponsiveRezized = $('section.page-info, .mainContainer'),
		pageInfoPath = $('.breadcrumbs.hide-on-med-and-down')

	if ($('.responsiveResizeOverlay').length === 0) {
		addResponsiveResizeOverlay()
	}

	if (blockResizerStoredMemory.selectedDevice === 'tablet' || blockResizerStoredMemory.selectedDevice === 'mobile') {
		addAttrForResize('width')
		addAttrForResize('height')
	}

	if (blockResizerStoredMemory.selectedDevice === 'tablet') {
		$(elementsForResponsiveRezized)
			.removeClass('resizeResponsiveView-mobile-open')
			.addClass('resizeResponsiveView-tablet-open')
		$(pageInfoPath).removeClass('breadcrumbs-mobile-open')
		$('.responsiveResizeOverlay-inside')
			.removeClass('inside-mobile')
			.addClass('inside-tablet')
		addStylesForResponsiveResize(780)
	} else if (blockResizerStoredMemory.selectedDevice === 'mobile') {
		$(elementsForResponsiveRezized)
			.removeClass('resizeResponsiveView-tablet-open')
			.addClass('resizeResponsiveView-mobile-open')
		$(pageInfoPath).addClass('breadcrumbs-mobile-open')
		$('.responsiveResizeOverlay-inside')
			.removeClass('inside-tablet')
			.addClass('inside-mobile')
		addStylesForResponsiveResize(400)
	} else {
		cleanPageViewForResponsiveResize()
	}

	var blocks = $('.mainContainer .blocks-box > .columns')
	setBlocksHeight(blocks)

	function addStylesForResponsiveResize(mainContainerWidthValue) {
		$('.responsiveResizeOverlay-inside').css({
			height: $(window).height() - $('header').outerHeight(),
			left: ($(window).outerWidth() - mainContainerWidthValue) / 2,
			top: $('header').outerHeight(),
		})
	}
}

export function cleanPageViewForResponsiveResize() {
	var elementsForResponsiveRezized = $('section.page-info, .mainContainer')
	var pageInfoPath = $('.breadcrumbs.hide-on-med-and-down')
	var blocks = $('.mainContainer .blocks-box > .columns')

	$(elementsForResponsiveRezized).removeClass('resizeResponsiveView-tablet-open')
	$(elementsForResponsiveRezized).removeClass('resizeResponsiveView-mobile-open')
	$(pageInfoPath).removeClass('breadcrumbs-mobile-open')
	$('.responsiveResizeOverlay').remove()

	$(blocks)
		.removeAttr('width-resizer')
		.removeAttr('height-resizer')
}

export function addResponsiveResizeOverlay() {
	if ($('#settings').hasClass('clicked')) {
		$('body').append("<div class='responsiveResizeOverlay'><div class='responsiveResizeOverlay-inside'></div></div>")
	}
}

export function addAttrForResize(resizeSide) {
	var blocks = $('.mainContainer .blocks-box > .columns'),
		holderForResizeValue,
		selectedResponsiveDeviceParameter,
		resizeAttrName = resizeSide + '-resizer'

	if (blockResizerStoredMemory.selectedDevice === 'tablet' || blockResizerStoredMemory.selectedDevice === 'mobile') {
		selectedResponsiveDeviceParameter = resizeSide + '-' + blockResizerStoredMemory.selectedDevice
	}

	for (var i = 0; i < blocks.length; i++) {
		var selector = $(blocks)[i]

		if (
			$(selector).attr(selectedResponsiveDeviceParameter) !== undefined &&
			$(selector).attr(selectedResponsiveDeviceParameter) < 13
		) {
			holderForResizeValue = parseInt($(selector).attr(selectedResponsiveDeviceParameter), 10)
		} else {
			holderForResizeValue = parseInt($(selector).attr(resizeSide), 10)
			if (resizeSide === 'width') {
				if (blockResizerStoredMemory.selectedDevice === 'tablet') {
					holderForResizeValue = Math.ceil(holderForResizeValue / 2) * 2
				} else if (blockResizerStoredMemory.selectedDevice === 'mobile') {
					holderForResizeValue = Math.ceil(holderForResizeValue / 3) * 3
				}
			}
		}
		$(selector).attr(resizeAttrName, holderForResizeValue)
	}
}

export function setBlocksHeightRecalculation(resizeUrl) {
	$('.dropdown-resize-options .dropdown-resize-devices:first-child').addClass('resize-devices-selected')
	var blocks = $('.mainContainer .blocks-box > .columns')
	setTimeout(function() {
		setBlocksHeight(blocks)
	}, 450)
	setTimeout(show.charts, 700)
	sendResizeAjax(setBlocksHeight(blocks), resizeUrl)
}

export function settingsResponsiveMenu() {
	$('#settings').click()
	$('#settings-responsiveMenu').toggleClass('settings-responsiveMenu-selected')
}

export function toastForSmallWindowForResize() {
	M.toast({
		html: "<i class='fa' style='padding-right: 15px;'>&#xf12a;</i>" + $('#settings').attr('data-size-error'),
		displayLength: 5000,
		classes: 'background-false',
	})
}
