export var log = function(v) {
	//show console log only in debug mode
	let val = v
	if (typeof value === 'undefined' || value === null) {
		val = 'test'
	}
}
export const browser = {
	//detect IE browsers
	ie: function() {
		if (/(MSIE|Trident\/|Edge\/)/i.test(navigator.userAgent)) $('html').attr('browser', 'IE')
	},
}
/**
 * ELIS functions
 * */
export const show = {
	charts: function() {
		if (!Highcharts) {
			return
		}

		var chartsArray = Highcharts.charts
		for (var i = -1; ++i < chartsArray.length; ) {
			var container = chartsArray[i].renderTo
			var containerWidth = container.offsetWidth
			var containerHeight = container.offsetHeight
			if (
				containerWidth !== chartsArray[i].container.offsetWidth ||
				containerHeight !== chartsArray[i].container.offsetHeight
			) {
				chartsArray[i].update({
					chart: {
						width: containerWidth,
						height: containerHeight,
					},
				})
			}
		}
	},
	top: function() {
		$('html, body').animate({ scrollTop: 0 }, 200)
	},
	back: function() {
		window.history.back()
	},
}

export const debounce = function(func, interval) {
	var timeout
	return function() {
		var context = this,
			args = arguments
		var later = function() {
			timeout = null
			func.apply(context, args)
		}
		clearTimeout(timeout)
		timeout = setTimeout(later, interval || 200)
	}
}

export const makeDebounceAjaxCall = debounce(function(value, link) {
	$.nette.ajax({
		type: 'POST',
		dataType: 'json',
		url: link,
		data: {
			code: value,
		},
	})
}, 500)

export function loadGraphs() {
	for (let i = -1; ++i < window.graphs.length; ) {
		window.graphs[i]()
	}
	window.graphs = []
}
