import { browser } from './$_functions'

export function menuWidth() {
	$('#main li.more').before($('#dropdown-more > li'))

	var navMain = document.getElementById('nav-main')
	if (!navMain) {
		return
	}
	navMain.style.width =
		document.querySelector('.top_header').offsetWidth -
		80 -
		(document.querySelector('section.account').offsetWidth +
			outerWidth(document.querySelector('.filter-button-link')) +
			outerWidth(document.getElementById('imglogo'))) +
		'px'

	var $navItemMore = document.querySelector('#main > li.more'),
		$navItems = document.querySelectorAll('#main > li:not(.more)'),
		navItemWidth = $navItemMore.offsetWidth,
		windowWidth = document.getElementById('main').offsetWidth

	for (var i = -1; ++i < $navItems.length; ) {
		navItemWidth += $navItems[i].offsetWidth
	}

	navItemWidth > windowWidth ? ($navItemMore.style.display = 'flex') : ($navItemMore.style.display = 'none')

	$navItems = Array.from($navItems)
	while (navItemWidth > windowWidth) {
		var lastItem = $navItems[$navItems.length - 1]
		navItemWidth -= outerWidth(lastItem)
		document
			.getElementById('dropdown-more')
			.insertBefore(lastItem, document.getElementById('dropdown-more').childNodes[0])
		$navItems.splice(-1, 1)
	}
}

export function responsiveMenu() {
	var x = document.getElementById('responsive-menu')

	if (document.getElementById('responsive-account').className !== 'd-none') {
		document.getElementById('responsive-account').className = 'd-none'
	}

	if (x.className === 'd-none') {
		x.className = ''
		x.style.opacity = 1
		$('ul.dropdown-elis:has(li.active):nth-child(2)').css('display', 'block')
	} else {
		x.className = 'd-none'
		x.style.opacity = 0
	}
}

export function responsiveAccount() {
	var x = document.getElementById('responsive-account')

	if ($('body').attr('data-menu') != 'off' && document.getElementById('responsive-menu').className !== 'd-none') {
		document.getElementById('responsive-menu').className = 'd-none'
	}

	if (x.className === 'd-none') {
		x.className = 'account'
	} else {
		x.className = 'd-none'
	}
}

export function menuOffsetX() {
	if ($('ul#main').length < 1) {
		return false
	}

	var offsetX = $('ul#main').offset().left

	$('.breadcrumbs').css('padding', '0 ' + offsetX + 'px')
	$('#filter').css('padding', '0 ' + (offsetX + 15) + 'px')
}

export function fixedFilterOptions() {
	var filter = $('#filter')

	if ($(filter).length < 1) {
		return false
	}

	$(window).scroll(function() {
		var scroll = $(window).scrollTop()

		if (
			scroll >=
			$('header .top_header')
				.parent()
				.outerHeight()
		) {
			filter.addClass('fixed')
		} else {
			filter.removeClass('fixed')
		}
	})
}
export function outerWidth(el) {
	if (!el) {
		return 0
	}
	var width = el.offsetWidth
	var style = getComputedStyle(el)

	width += parseInt(style.marginLeft) + parseInt(style.marginRight)
	return width
}

export var app = {
	loader: function() {
		browser.ie()
		menuWidth()
		setTimeout(menuWidth, 500)
		// menuOffsetX(); setTimeout(menuOffsetX, 500);
		fixedFilterOptions()

		// Show list items in collapsed menu (#responsive-menu)
		var navCategories = document.querySelectorAll('.nav-category')

		for (var i = -1; ++i < navCategories.length; ) {
			navCategories[i].addEventListener('click', function(e) {
				e.preventDefault()
				var dropdown = e.target.closest('li').querySelector('.dropdown-elis')
				if (dropdown.style.display === 'block') {
					dropdown.style.display = 'none'
				} else {
					dropdown.style.display = 'block'
				}
			})
		}

		// $ custom load:trigger as $(document).on('load:trigger', function() {});
		$(document).trigger('load:trigger')
	},
	resizer: function() {
		menuWidth()
		// menuOffsetX();
		show.charts()
	},
}

window.addEventListener('load', function() {
	// init on load
	app.loader()
})
window.onresize = resizeCallback

function resizeCallback() {
	app.resizer()
}
