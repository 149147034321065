export function closeFilterSelection(e) {
	$(document).ready(function() {
		$('.filter-btn-option').removeClass('selected')
		$(e)
			.closest('.filter-selection')
			.css('transform', 'translateX(-115%)')
	})
}

export function openFilterSelection(e) {
	$(document).ready(function() {
		if ($(e).hasClass('selected')) {
			$('.filter-btn-option').removeClass('selected')
			$('.filter-selection').css('transform', 'translateX(-115%)')
			return false
		}
		$('.filter-btn-option').removeClass('selected')
		$(e).addClass('selected')
		$('.filter-selection').css('transform', 'translateX(-115%)')
		$('.' + $(e).attr('data-filter-selection')).css('transform', 'translateX(-5px)')
	})
}

export function showFilter() {
	$(document).ready(function() {
		$(window).scrollTop(0)
		if ($('.apply-filter').hasClass('filter-button')) {
			/*condition is always true*/
			showFilterTrue()
		} else if ($('.apply-filter').hasClass('filter-apply-button')) {
			$('.filter-submit').trigger('click')
			showFilterFalse()
		} else {
			showFilterFalse()
		}
	})
}

export function showHelp() {
	$(document).ready(function() {
		if ($('a.apply-help').hasClass('help-button')) {
			/*condition is always true*/
			/*hide filter aside if it is on*/
			showHelpTrue()
		} else {
			showHelpFalse()
		}
	})
}

export function showFilterTrue() {
	if ($('i.fa.fa-question').hasClass('clicked-help')) {
		showHelpFalse()
	}
	$('.apply-filter').addClass('filter-apply-button')
	$('.apply-filter').removeClass('filter-button')
	$('.apply-filter').html(
		'<span>Apply Filter</span>\n' +
			'                <svg width="12px" height="10px" viewBox="0 0 12 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
			'                    <title>Path 3</title>\n' +
			'                    <desc>Created with Sketch.</desc>\n' +
			'                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
			'                        <g id="ipsos-pitch-v7---dashboard" transform="translate(-198.000000, -64.000000)" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="2">\n' +
			'                            <g id="Filter-v2b---multiselect" transform="translate(0.000000, 46.000000)">\n' +
			'                                <g id="Filter-button" transform="translate(99.000000, 0.000000)">\n' +
			'                                    <polyline id="Path-3" points="99.9440944 22 103.298874 26.0371216 110.109879 19"></polyline>\n' +
			'                                </g>\n' +
			'                            </g>\n' +
			'                        </g>\n' +
			'                    </g>\n' +
			'                </svg>',
	)
	$('.filter-bar').css('visibility', 'visible')
	$('.filter-bar').css('opacity', '1')
	$('.filter-inputs').css('transform', 'translateX(0px)')
	$('body').css('overflow', 'hidden')
	setTimeout(function() {
		$('.filter-selection').css('opacity', '1')
	}, 200)
}

export function showFilterFalse() {
	$('.apply-filter').addClass('filter-button')
	$('.apply-filter').removeClass('filter-apply-button')
	$('.apply-filter').html(
		'<svg width="22px" height="23px" viewBox="0 0 22 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
			'                    <g id="Pitch---Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
			'                        <g id="ipsos-pitch-v7" transform="translate(-110.000000, -59.000000)" stroke="#00A4A7" stroke-width="2">\n' +
			'                            <g id="Filter-button" transform="translate(99.000000, 47.000000)">\n' +
			'                                <polygon id="Path-2" points="12 13 32 13 32 15.2222222 24.2222222 24.1111111 24.2222222 33 19.7777778 30.7777778 19.7777778 24.1111111 12 15.2222222"></polygon>\n' +
			'                            </g>\n' +
			'                        </g>\n' +
			'                    </g>\n' +
			'                </svg>',
	)
	$('.filter-bar').css('opacity', '0')
	$('.filter-bar').css('visibility', 'hidden')
	$('.filter-inputs').css('transform', 'translateX(-580px)')
	$('.filter-selection').css('opacity', '0')
	$('.filter-selection').css('transform', 'translateX(-115%)')
	$('.filter-btn-option').removeClass('selected')
	$('body').css('overflow', 'auto') /*$('body').css('overflow','scroll');*/
}

export function showHelpTrue() {
	if ($('.apply-filter').hasClass('filter-apply-button') === true) {
		showFilterFalse()
	}
	$('a.apply-help').addClass('help-apply-button')
	$('a.apply-help').removeClass('help-button')
	$('a.apply-help').html('<i class="fa fa-question help-icon help-icon-clicked" aria-hidden="true"></i>')
	$('a.apply-help').css('text-decoration', 'none')

	$('.help-bar').css('visibility', 'visible')
	$('.help-bar').css('opacity', '1')
	$('.help-inputs').css('transform', 'translateX(0px)')
	/*$('body').css('overflow','hidden');*/
}

export function showHelpFalse() {
	$('a.apply-help').addClass('help-button')
	$('a.apply-help').removeClass('help-apply-button')
	$('a.apply-help').html('<i class="fas fa-question account_icon anmt help-icon" aria-hidden="true"></i>')
	$('.help-bar').css('opacity', '0')
	$('.help-bar').css('visibility', 'hidden')
	$('.help-inputs').css('transform', 'translateX(580px)')
	/*$('body').css('overflow','auto');*/
}
