import Nette from 'nette-forms'
import 'webpack-jquery-ui'
import 'webpack-jquery-ui/resizable'
import 'webpack-jquery-ui/sortable'
import $ from 'jquery'
import spectrum from 'spectrum-colorpicker'
import fineUploader from 'fine-uploader/lib/jquery/traditional'
import ace from 'ace-builds/src-min-noconflict/ace'
import Cookies from 'js-cookie'

// import './_elis/resizer'
import './_elis/filter/customizedJqueryUi'

import * as Highcharts from 'highcharts'
import * as HC_solidgauge from 'highcharts/modules/solid-gauge'
import * as HC_funnel from 'highcharts/modules/funnel'
import * as HC_exporting from 'highcharts/modules/exporting'
import * as HC_exportData from 'highcharts/modules/export-data'
import * as HC_more from 'highcharts/highcharts-more'
import * as HC_heatmap from 'highcharts/modules/heatmap'
import * as HC_wordcloud from 'highcharts/modules/wordcloud'

/* TypeScript */
import { crosstable, crosstableEditor } from 'modules'
import { cn } from 'utils'

/* JavaScript */
import { client } from './client/client'
import { alerts } from './_elis/alerts'
import { handleBarAnimation } from './_elis/bar_animation'
import { resizetrigger } from './_elis/resizer'

import * as appFunctions from './_elis/$_functions'
import * as filterController from './_elis/filter-controller'
import * as responsiveMenu from './_elis/responsive_menu'
import * as blockResizer from './_elis/modules/editor/blockresizer'
import * as filter from './_elis/filter/filter'
import * as srcExport from './_assets/zcanvas/scrExport'
import * as RGBColor from './_assets/zcanvas/rgbcolor'

if ($) {
	$.extend({ spectrum })
	$.extend({ fineUploader })
}

window.jQuery = $
window.$ = $
window.Cookies = Cookies

// Nette Forms
window.Nette = Nette

// Settings
window.blockAttributeData = {}

// Ace
window.ace = ace

// Highcharts
HC_wordcloud(Highcharts)
HC_heatmap(Highcharts)
HC_more(Highcharts)
HC_solidgauge(Highcharts)
HC_funnel(Highcharts)
HC_exporting(Highcharts)
HC_exportData(Highcharts)
window.Highcharts = Highcharts

// App functions
window.loadGraphs = appFunctions.loadGraphs
window.log = appFunctions.log
window.show = appFunctions.show
window.debounce = appFunctions.debounce
window.makeDebounceAjaxCall = appFunctions.makeDebounceAjaxCall
window.resizetrigger = resizetrigger

// Filter
window.itemChecker = filter.itemChecker
window.ajaxChange = filter.ajaxChange
window.filterDate = filter.filterDate
window.filterSelectChoose = filter.filterSelectChoose
window.filterSelectSearch = filter.filterSelectSearch
window.filterSelectUpdate = filter.filterSelectUpdate

// Responsive menu
window.menuWidth = responsiveMenu.menuWidth
window.responsiveMenu = responsiveMenu.responsiveMenu
window.responsiveAccount = responsiveMenu.responsiveAccount
window.menuOffsetX = responsiveMenu.menuOffsetX
window.fixedFilterOptions = responsiveMenu.fixedFilterOptions
window.outerWidth = responsiveMenu.outerWidth
window.app = responsiveMenu.app

// Alerts
window.alertAction = alerts.alertAction
window.openAlertModal = alerts.openAlertModal
window.closeAlertModal = alerts.closeAlertModal
window.alertsEvents = alerts.alertsEvents

// Block resizer
window.blocksAdjustment = blockResizer.blocksAdjustment
window.resize = blockResizer.resize
window.sort = blockResizer.sort
window.grider = blockResizer.grider
window.setChosenDeviceToVariable = blockResizer.setChosenDeviceToVariable
window.setBlocksHeight = blockResizer.setBlocksHeight
window.sendResizeAjax = blockResizer.sendResizeAjax
window.pageViewForResponsiveResize = blockResizer.pageViewForResponsiveResize
window.cleanPageViewForResponsiveResize = blockResizer.cleanPageViewForResponsiveResize
window.addResponsiveResizeOverlay = blockResizer.addResponsiveResizeOverlay
window.addAttrForResize = blockResizer.addAttrForResize
window.setBlocksHeightRecalculation = blockResizer.setBlocksHeightRecalculation
window.settingsResponsiveMenu = blockResizer.settingsResponsiveMenu
window.toastForSmallWindowForResize = blockResizer.toastForSmallWindowForResize

// Filter controller
window.closeFilterSelection = filterController.closeFilterSelection
window.openFilterSelection = filterController.openFilterSelection
window.showFilter = filterController.showFilter
window.showHelp = filterController.showHelp
window.showFilterTrue = filterController.showFilterTrue
window.showFilterFalse = filterController.showFilterFalse
window.showHelpTrue = filterController.showHelpTrue

// Src export
window.capture = srcExport.capture
window.exportPdf = srcExport.exportPdf
window.nextStep = srcExport.nextStep
window.resizeScreen = srcExport.resizeScreen

// RGB color
window.RGBColor = RGBColor.RGBColor

document.addEventListener('DOMContentLoaded', () => {
	alerts.alertsEvents()
	client()
	handleBarAnimation()
	crosstable()
	crosstableEditor()
})

document.addEventListener('load', () => {
	// init on load
	responsiveMenu.app.loader()
})
window.onresize = responsiveMenu.app.resizer
