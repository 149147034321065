import Sortable from 'sortablejs'
import $ from 'jquery'
import M from 'materialize-css'

import { strings, cn } from 'utils'
import { Connect, useState } from 'hooks'

const FONT_SIZE = 12
const COL_MIN_WIDTH = 50
const PADDING = 8

const HEAD_COL_SELECTOR = 'data-ct-col-head'
const HEAD_COL_SPLIT_ITEM_SELECTOR = 'data-ct-col-head-split-item'
const STATIC_ROW_SELECTOR = 'data-ct-row-static'
const STATIC_COL_SELECTOR = 'data-ct-col-static'
const STATIC_COL_SPLIT_SELECTOR = 'data-ct-static-col-split'
const COL_SELECTOR = 'data-ct-col'
const ROW_SELECTOR = 'data-ct-row'
const COL_SPLIT_SELECTOR = 'data-ct-col-split'
const COL_SPLIT_ITEM_SELECTOR = 'data-ct-col-split-item'
const QUESTION_NAME_SELECTOR = 'data-ct-question-name'

const getScope = () => document.querySelector<HTMLElement>('.crosstable')
const getContent = (scope: HTMLElement) => scope.querySelector<HTMLElement>('.js-ct-content')
const getLoader = (scope: HTMLElement) => scope.querySelector<HTMLElement>('.js-ct-loader')
const getStatic = (scope: HTMLElement) => scope.querySelector<HTMLElement>('.js-ct-static')
const getRows = (scope: HTMLElement) => scope.querySelector<HTMLElement>('.js-ct-rows')
const getRowsHead = (scope: HTMLElement) => scope.querySelector<HTMLElement>('.js-ct-row-head')
const getActiveRows = (scope: HTMLElement) => scope.querySelector<HTMLElement>('.js-ct-modal-active-rows')
const getActiveColumns = (scope: HTMLElement) => scope.querySelector<HTMLElement>('.js-ct-modal-active-columns')
const getModalLegend = (scope: HTMLElement) => scope.querySelector<HTMLElement>('.js-ct-modal-legend')
const getModalConfig = (scope: HTMLElement) => scope.querySelector<HTMLElement>('.js-ct-modal-config')
const getModalConfigQuestions = (scope: HTMLElement) =>
	scope.querySelector<HTMLElement>('.js-ct-modal-config-questions')

const makeLoader = () => {
	const scope = getScope()

	if (!scope) return
	const content = getContent(scope)
	const loader = getLoader(scope)

	if (!content || loader) return
	const fragment = document.createDocumentFragment()
	const newLoader = document.createElement('div')
	const newLoaderShape = document.createElement('div')

	newLoader.setAttribute('class', 'crosstable__loader js-ct-loader')
	newLoaderShape.setAttribute('class', 'crosstable__loader-shape')

	newLoader.appendChild(newLoaderShape)
	fragment.appendChild(newLoader)
	content.appendChild(fragment)
}

const destroyLoader = () => {
	const scope = getScope()

	if (!scope) return
	const content = getContent(scope)
	const loader = getLoader(scope)

	if (!loader || !content) return

	content.removeChild(loader)
}

const makeCrosstableColsWidth = () => {
	const scope = getScope()
	let tableWidths = {} as { [key: string]: { width: number; splits: { [key: string]: number } } }

	if (!scope) return
	const headCols = scope.querySelectorAll<HTMLElement>(`[${HEAD_COL_SELECTOR}]`)
	const headColsLength = headCols.length

	if (headColsLength === 0) return
	for (let i = 0; i < headColsLength; i++) {
		const headCol = headCols[i]
		const headColIndex = headCol.getAttribute(`${HEAD_COL_SELECTOR}`)
		const headColSplitItems = headCol.querySelectorAll<HTMLElement>(`[${HEAD_COL_SPLIT_ITEM_SELECTOR}]`)
		const headColSplitItemsLength = headColSplitItems.length

		if (!headColIndex || headColSplitItemsLength === 0) continue
		tableWidths = {
			...tableWidths,
			[headColIndex]: {
				...tableWidths[headColIndex],
				width: headCol.offsetWidth,
			},
		}

		for (let j = 0; j < headColSplitItemsLength; j++) {
			const headColSplitItem = headColSplitItems[j]
			const headColSplitItemIndex = headColSplitItem.getAttribute(`${HEAD_COL_SPLIT_ITEM_SELECTOR}`)

			if (!headColSplitItemIndex) continue
			tableWidths = {
				...tableWidths,
				[headColIndex]: {
					...tableWidths[headColIndex],
					splits: {
						...tableWidths[headColIndex].splits,
						[headColSplitItemIndex]: headColSplitItem.offsetWidth,
					},
				},
			}
		}
	}

	const cols = scope.querySelectorAll<HTMLElement>(`[${COL_SELECTOR}]`)
	const colsLength = cols.length

	if (colsLength === 0) return
	for (let i = 0; i < colsLength; i++) {
		const col = cols[i]
		const colSplitItems = col.querySelectorAll<HTMLElement>(`[${COL_SPLIT_ITEM_SELECTOR}]`)
		const colSplitItemsLength = colSplitItems.length
		const colIndex = col.getAttribute(`${COL_SELECTOR}`)

		if (!colIndex || colSplitItemsLength === 0) continue
		const colWidths = tableWidths[colIndex]

		if (colWidths) {
			const { width } = colWidths
			col.setAttribute('style', `max-width: ${width}px; flex: 0 1 ${width}px;`)
		}

		for (let j = 0; j < colSplitItemsLength; j++) {
			const colSplitItem = colSplitItems[j]
			const colSplitItemIndex = colSplitItem.getAttribute(`${COL_SPLIT_ITEM_SELECTOR}`)

			if (!colSplitItem || !colSplitItemIndex || !colWidths) continue
			const { splits } = colWidths
			const splitWidth = splits[colSplitItemIndex]
			colSplitItem.setAttribute('style', `max-width: ${splitWidth}px; flex: 0 1 ${splitWidth}px;`)
		}
	}
}

const makeStaticColsWidth = () => {
	const scope = getScope()

	if (!scope) return
	const widths = {} as { [key: string]: number[] }
	const staticCols = scope.querySelectorAll<HTMLElement>(`[${STATIC_COL_SELECTOR}]`)
	const staticColsLength = staticCols.length

	if (staticColsLength === 0) return
	for (let i = 0; i < staticColsLength; i++) {
		const staticCol = staticCols[i]

		if (!staticCol) continue
		const staticColIndex = staticCol.getAttribute(`${STATIC_COL_SELECTOR}`)

		if (!staticColIndex) continue
		if (!widths[staticColIndex]) widths[staticColIndex] = []
		widths[staticColIndex].push(staticCol.offsetWidth)
	}

	for (let i = 0; i < staticColsLength; i++) {
		const staticCol = staticCols[i]

		if (!staticCol) continue
		const staticColIndex = staticCol.getAttribute(`${STATIC_COL_SELECTOR}`)
		const widthsKeys = Object.keys(widths)

		if (!staticColIndex) continue
		if (widthsKeys.indexOf(staticColIndex) < 0) continue
		const staticColWidth = Math.max(...widths[staticColIndex])
		staticCol.setAttribute('style', `max-width: ${staticColWidth}px; flex: 0 1 ${staticColWidth}px;`)
	}
}

const makeColsHeightByStatic = () => {
	const scope = getScope()
	let heights = {} as { [key: string]: { [key: string]: number } }

	if (!scope) return
	const staticContainer = getStatic(scope)

	if (!staticContainer) return
	const lastStaticCols = staticContainer.querySelectorAll(`[${STATIC_COL_SELECTOR}="2"]`)
	const lastStaticColsLength = lastStaticCols.length

	if (!lastStaticColsLength) return
	for (let i = 0; i < lastStaticColsLength; i++) {
		const staticCol = lastStaticCols[i]

		if (!staticCol) continue
		const staticColItems = staticCol.querySelectorAll<HTMLElement>(`[${STATIC_COL_SPLIT_SELECTOR}]`)
		const staticColItemsLength = staticColItems.length

		if (staticColItemsLength === 0) continue
		for (let j = 0; j < staticColItemsLength; j++) {
			const staticColItem = staticColItems[j]

			if (!staticColItem) continue
			const staticColItemIndex = staticColItem.getAttribute(STATIC_COL_SPLIT_SELECTOR)

			if (!staticColItemIndex) continue
			const height = staticColItem.offsetHeight
			heights = {
				...heights,
				[i + 1]: {
					...heights[i + 1],
					[staticColItemIndex]: height,
				},
			}
		}
	}

	const rows = scope.querySelectorAll<HTMLElement>(`[${ROW_SELECTOR}]`)
	const staticRows = scope.querySelectorAll<HTMLElement>(`[${STATIC_ROW_SELECTOR}]`)

	if (!rows || !staticRows) return
	const rowsLength = rows.length
	const staticRowsLength = staticRows.length

	if (rowsLength === 0 || staticRowsLength === 0) return
	for (let i = 0; i < rowsLength; i++) {
		const row = rows[i]
		const rowIndex = row.getAttribute(ROW_SELECTOR)

		if (!rowIndex) continue
		const colSplits = row.querySelectorAll<HTMLElement>(`[${COL_SPLIT_SELECTOR}]`)
		const colSplitsLength = colSplits.length

		if (colSplitsLength === 0) {
			const staticRow = staticRows[i]

			if (!staticRow) continue
			row.setAttribute('style', `height: ${staticRow.offsetHeight}px;`)
			continue
		}
		for (let j = 0; j < colSplitsLength; j++) {
			const colSplit = colSplits[j]
			const colSplitIndex = colSplit.getAttribute(COL_SPLIT_SELECTOR)

			if (!colSplitIndex) continue
			const height = heights[rowIndex][colSplitIndex]
			colSplit.setAttribute('style', `height: ${height}px;`)
		}
	}
}

const makeStaticHeightAndPosition = () => {
	const scope = getScope()

	if (!scope) return
	const staticContainer = getStatic(scope)
	const rowsContainer = getRows(scope)
	const rowsHead = getRowsHead(scope)

	if (!staticContainer || !rowsContainer || !rowsHead) return
	staticContainer.setAttribute('style', `margin-top: ${rowsHead.offsetHeight}px;`)
	const firstRow = rowsContainer.querySelector<HTMLElement>(`[${ROW_SELECTOR}]`)
	const staticStyle = staticContainer.getAttribute('style')

	if (!firstRow) return
	const rowMinHeight = firstRow.offsetHeight
	const firstRowCols = firstRow.querySelectorAll(`[${COL_SELECTOR}]`)
	const firstRowColsLength = firstRowCols.length

	if (firstRowColsLength < 2 && staticStyle) {
		let staticTitleColsHeight = 0
		let staticSplitColsHeight = 0
		const staticTitleCols = staticContainer.querySelectorAll<HTMLElement>(`[${STATIC_COL_SELECTOR}="1"]`)
		const staticSplitCols = staticContainer.querySelectorAll<HTMLElement>(`[${STATIC_COL_SELECTOR}="2"]`)
		const staticTitleColsLength = staticTitleCols.length
		const staticSplitColsLength = staticSplitCols.length

		if (staticTitleColsLength > 0) {
			for (let i = 0; i < staticTitleColsLength; i++) {
				const staticTitleCol = staticTitleCols[i]

				if (!staticTitleCol) continue
				staticTitleColsHeight += staticTitleCol.offsetHeight
			}
		}
		if (staticSplitColsLength > 0) {
			for (let i = 0; i < staticSplitColsLength; i++) {
				const staticSplitCol = staticSplitCols[i]

				if (!staticSplitCol) continue
				const staticSplitColItems = staticSplitCol.querySelectorAll<HTMLElement>(`[${STATIC_COL_SPLIT_SELECTOR}]`)
				const staticSplitColItemsLength = staticSplitColItems.length
				let currentHeight = 0

				for (let j = 0; j < staticSplitColItemsLength; j++) {
					const staticSplitColItem = staticSplitColItems[j]

					if (!staticSplitColItem) continue
					currentHeight += staticSplitColItem.offsetHeight
				}

				staticSplitColsHeight += currentHeight > rowMinHeight ? currentHeight : rowMinHeight
			}
		}

		if (staticTitleColsHeight > 0 && staticSplitColsHeight > 0) {
			staticContainer.setAttribute(
				'style',
				`${staticStyle} height: ${
					staticTitleColsHeight > staticSplitColsHeight ? staticTitleColsHeight : staticSplitColsHeight
				}px;`,
			)
		}

		makeColsHeightByStatic()
	}

	const rowsContainerStyle = rowsContainer.getAttribute('style')

	if (rowsContainerStyle) {
		rowsContainer.setAttribute('style', `${rowsContainerStyle} height: ${staticContainer.offsetHeight}px;`)
	} else {
		rowsContainer.setAttribute('style', `height: ${staticContainer.offsetHeight}px;`)
	}
}

const makeTableContentMinWidth = () => {
	const scope = getScope()

	if (!scope) return
	const rowsHead = getRowsHead(scope)
	const rowsContainer = getRows(scope)

	if (!rowsHead || !rowsContainer) return
	const headCols = rowsHead.querySelectorAll<HTMLElement>(`[${HEAD_COL_SELECTOR}]`)
	const headColsLength = headCols.length

	if (headColsLength < 2) return
	let contentMinWidth = 0

	for (let i = 0; i < headColsLength; i++) {
		const headCol = headCols[i]
		const headColSplitItems = headCol.querySelectorAll<HTMLElement>(`[${HEAD_COL_SPLIT_ITEM_SELECTOR}]`)
		const headColSplitItemsLength = headColSplitItems.length

		if (headColSplitItemsLength === 0) continue
		for (let j = 0; j < headColSplitItemsLength; j++) {
			const headColSplitItem = headColSplitItems[j]
			const stringWidth = strings.getTextWidth(headColSplitItem.innerText, `${FONT_SIZE}pt Roboto`)

			if (stringWidth > COL_MIN_WIDTH) {
				contentMinWidth += stringWidth
			} else {
				contentMinWidth += COL_MIN_WIDTH
			}
			contentMinWidth += PADDING * 2
		}
	}

	rowsHead.setAttribute('style', `min-width: ${contentMinWidth}px;`)
	rowsContainer.setAttribute('style', `min-width: ${contentMinWidth}px;`)
}

const handleTableSizes = () => {
	makeTableContentMinWidth()
	makeStaticColsWidth()
	makeCrosstableColsWidth()
	makeColsHeightByStatic()
	makeStaticHeightAndPosition()
}

const handleEvents = () => {
	window.addEventListener('resize', handleTableSizes)
}

const handleAjax = (link: string, name: string) => {
	makeLoader()
	// @ts-ignore
	$.nette.ajax({
		url: link.replace('replaceParam', name),
	})
}

const handleSortable = ({ getState, setState }: Connect) => {
	const { scope } = getState()

	if (!scope) return
	const activeRows = getActiveRows(scope)
	const activeColumns = getActiveColumns(scope)

	if (!activeRows || !activeColumns) return
	const modalQuestions = getModalConfigQuestions(scope)

	if (!modalQuestions) return
	const rowsHandle = activeRows.getAttribute('data-ct-update-link')

	if (!rowsHandle) return
	const sortableActiveColums = new Sortable(activeColumns, {
		group: 'shared',
		animation: 150,
		draggable: '.js-ct-modal-active-columns-item',
		onAdd: e => {
			const { item } = e
			const name = item.getAttribute(QUESTION_NAME_SELECTOR)

			if (!name) return
			handleAjax(rowsHandle, name)
		},
		onRemove: e => {
			const { item } = e
			const name = item.getAttribute(QUESTION_NAME_SELECTOR)

			if (!name) return
			handleAjax(rowsHandle, name)
		},
	})

	const colsHandle = activeColumns.getAttribute('data-ct-update-link')
	const colsRemoveHandle = activeColumns.getAttribute('data-ct-remove-link')

	const handleQuestionRestrictStart = (e: Sortable.SortableEvent) => {
		const { item } = e
		const isAttribute = cn.hasClass(item, 'js-ct-is-attribute')
		const { parentNode } = activeColumns
		const parent = parentNode as HTMLElement

		if (!isAttribute || !parentNode) return
		sortableActiveColums.options.disabled = true
		parent.setAttribute('style', 'opacity: 0.3;')
	}

	const handleQuestionRestrictEnd = () => {
		const { parentNode } = activeColumns
		const parent = parentNode as HTMLElement

		if (!parentNode) return
		sortableActiveColums.options.disabled = false
		activeColumns.setAttribute('style', 'background-color: transparent;')
		parent.setAttribute('style', 'opacity: 1;')
	}

	if (!colsHandle || !colsRemoveHandle) return
	const sortableActiveRows = new Sortable(activeRows, {
		group: 'shared',
		animation: 150,
		draggable: '.js-ct-modal-active-rows-item',
		onAdd: e => {
			const { item } = e
			const name = item.getAttribute(QUESTION_NAME_SELECTOR)

			if (!name) return
			handleAjax(colsHandle, name)
		},
		onRemove: e => {
			const { item } = e
			const name = item.getAttribute(QUESTION_NAME_SELECTOR)

			if (!name) return
			handleAjax(colsRemoveHandle, name)
		},
		onStart: e => {
			handleQuestionRestrictStart(e)
		},
		onEnd: () => {
			handleQuestionRestrictEnd()
		},
	})

	const sortableQuestions = new Sortable(modalQuestions, {
		group: 'shared',
		animation: 150,
		draggable: '.js-ct-modal-config-questions-item',
		onStart: e => {
			handleQuestionRestrictStart(e)
		},
		onEnd: () => {
			handleQuestionRestrictEnd()
		},
	})

	setState({ sortableQuestions, sortableActiveRows, sortableActiveColums })
}

const handleModalEvents = ({ getState, setState, connect }: Connect) => {
	const { scope } = getState()

	if (!scope) return
	let modalLegend = getModalLegend(scope)
	let modalConfig = getModalConfig(scope)

	if (!modalLegend || !modalConfig) return
	M.Modal.init(modalLegend)
	M.Modal.init(modalConfig)

	// @ts-ignore
	$.nette.ext({
		before: () => {
			makeLoader()
		},
		complete: ({ reinitAll }: { [key: string]: any }) => {
			setState({ scope: getScope() }, actualState => {
				handleTableSizes()
				connect(handleSortable)
				destroyLoader()

				if (!reinitAll) return
				modalLegend = getModalLegend(actualState.scope)
				modalConfig = getModalConfig(actualState.scope)

				if (!modalConfig) return
				M.Modal.init(modalConfig)

				if (!modalLegend) return
				M.Modal.init(modalLegend)
			})
		},
	})
}

export const crosstable = () => {
	const { connect } = useState('crosstable', { scope: getScope() })

	handleTableSizes()
	destroyLoader()

	handleEvents()
	connect(handleSortable)
	connect(handleModalEvents)
}
