import Sortable from 'sortablejs'
import $ from 'jquery'

import { crosstable } from 'modules'

const getScope = () => document.querySelector<HTMLElement>('.js-ct-editor-questions')
const getAvailableQuestionsContainer = (scope: HTMLElement) =>
	scope.querySelector<HTMLElement>('.js-ct-editor-available-questions')
const getAssignedQuestionsContainer = (scope: HTMLElement) =>
	scope.querySelector<HTMLElement>('.js-ct-editor-assigned-questions')
const getQuestionnaireSelect = (scope: HTMLElement) =>
	scope.querySelector<HTMLElement>('.js-ct-editor-questionnaire-select')
const getQuestionnaireSubmit = (scope: HTMLElement) =>
	scope.querySelector<HTMLElement>('.js-ct-editor-questionnaire-submit')

const QUESTION_ID_SELECTOR = 'data-question'
const QUESTION_HANDLE_SELECTOR = 'data-handle'

const handleAjax = (link: string, questionId: number) => {
	// @ts-ignore
	$.nette.ajax({
		url: link.replace('-id=0;', `-id=${questionId};`),
		complete: () => {
			crosstable()
		},
	})
}

const handleSortable = () => {
	const scope = getScope()

	if (!scope) return
	const availableQuestionsContainer = getAvailableQuestionsContainer(scope)
	const assignedQuestionsContainer = getAssignedQuestionsContainer(scope)

	if (!availableQuestionsContainer || !assignedQuestionsContainer) return
	const handle = assignedQuestionsContainer.getAttribute(QUESTION_HANDLE_SELECTOR)
	if (!handle) return

	const sortableAvailable = new Sortable(assignedQuestionsContainer, {
		group: 'sharedCtEditor',
		animation: 150,
		draggable: 'div',
		onAdd: e => {
			const { item } = e
			const questionId = item.getAttribute(QUESTION_ID_SELECTOR)

			if (!questionId) return
			handleAjax(handle, questionId)
		},
		onRemove: e => {
			const { item } = e
			const questionId = item.getAttribute(QUESTION_ID_SELECTOR)

			if (!questionId) return
			handleAjax(handle, questionId)
		},
	})

	const sortableAssigned = new Sortable(availableQuestionsContainer, {
		group: 'sharedCtEditor',
		animation: 150,
		draggable: 'div',
	})
}

const handleQuestionnaireChange = () => {
	const scope = getScope()

	if (!scope) return
	const questionnaireSelect = getQuestionnaireSelect(scope)

	if (!questionnaireSelect) return
	questionnaireSelect.addEventListener('change', () => {
		const questionnaireSubmit = getQuestionnaireSubmit(scope)

		if (!questionnaireSubmit) return
		questionnaireSubmit.click()
	})
}

export const crosstableEditor = () => {
	handleSortable()
	handleQuestionnaireChange()
	$.nette.ext('submit', {
		success: () => {
			handleSortable()
		},
	})
}
